import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ApproveDeclineFriendsRequestGQL, NotificationType } from 'src/app/services/hobbyts.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { WeekDayPipe } from 'src/app/pipe/weekday.pipe';
import { DayPipe } from 'src/app/pipe/dayPipe.pipe';
import { hobbiesTranslations } from 'src/app/shared/constants';
import { localizeFreeSpot } from 'src/app/utils/profile.utils';

@Component({
  selector: 'hb-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [MatIconModule, MatCardModule, TitleCasePipe, DatePipe, WeekDayPipe, DayPipe]
})
export class NotificationDetailComponent implements OnInit {
  @Input() public notification!: any;
  public hobbyTranslation: string;
  public localizedFreeSpot: string;

  constructor(
    private router: Router,
    private apollo: Apollo,
    private approveDeclineRequestGQL: ApproveDeclineFriendsRequestGQL,
    private matIconRegistry: MatIconRegistry
  ) {}

  public ngOnInit(): void {
    this.localizedFreeSpot = localizeFreeSpot(this.notification.playerLimit);
    this.hobbyTranslation = hobbiesTranslations[this.notification?.hobby];
  }

  public checkIfIconExists(iconName: string): boolean {
    return !!this.matIconRegistry.getNamedSvgIcon(iconName);
  }

  public expand(): void {
    this.notification.collapsed = !this.notification.collapsed;
  }

  public buttonClicked(type: string, notification: NotificationType): void {
    if (type === 'view_profile') {
      this.router.navigate(['profile', notification.userId]);
    }

    if (type === 'accept_friend' || type === 'decline_friend') {
      const mutationObj = {
        userId: notification?.userId,
        isAccept: type === 'accept_friend'
      };

      this.notification.isUserResponded = 1;
      this.notification.collapsed = false;
      this.apollo
        .mutate({
          mutation: this.approveDeclineRequestGQL.document,
          variables: {
            acceptDeclineRequestInput: mutationObj
          }
        })
        .subscribe();
    }

    if (type === 'view_event') {
      this.router.navigate(['event-details', notification.eventId]);
    }
  }
}
